<template>
  <b-container fluid>
    <h4 class="mb-4">Representantes</h4>
    <b-row>
      <b-col>
        <b-form @submit="onSubmit">
          <form-input
            v-model="form.nome"
            label="Nome:"
            placeholder="Digite o nome"
            icon="person"
            required
          />

          <form-input
            v-model="form.regiao_atendimento"
            label="Região de atendimento:"
            placeholder="Digite a região de atendimento"
            icon="geo-alt"
            required
          />

          <form-input
            v-model="form.email"
            label="Email:"
            placeholder="Digite o email"
            icon="at"
            required
          />

          <b-form-group
            id="input-group-4"
            label="Contato 1:"
            label-for="input-4"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input
                  v-model="form.whatsapp1"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  class="mr-1"
                  :disabled="!form.contato1"
                />
                Whatsapp
              </b-input-group-prepend>
              <b-form-input
                v-model="form.contato1"
                placeholder="Digite o contato"
                aria-label="Text input with checkbox"
                @input="uncheckZap($event, 'whatsapp1')"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="input-group-5"
            label="Contato 2:"
            label-for="input-5"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input
                  v-model="form.whatsapp2"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  class="mr-1"
                  :disabled="!form.contato2"
                />
                Whatsapp
              </b-input-group-prepend>
              <b-form-input
                v-model="form.contato2"
                placeholder="Digite o contato"
                aria-label="Text input with checkbox"
                @input="uncheckZap($event, 'whatsapp2')"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label="Contato 3:"
            label-for="input-6"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input
                  v-model="form.whatsapp3"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  class="mr-1"
                  :disabled="!form.contato3"
                />
                Whatsapp
              </b-input-group-prepend>
              <b-form-input
                v-model="form.contato3"
                placeholder="Digite o contato"
                aria-label="Text input with checkbox"
                @input="uncheckZap($event, 'whatsapp3')"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label="Contato 4:"
            label-for="input-7"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input
                  v-model="form.whatsapp4"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  class="mr-1"
                  :disabled="!form.contato4"
                />
                Whatsapp
              </b-input-group-prepend>
              <b-form-input
                v-model="form.contato4"
                placeholder="Digite o contato"
                aria-label="Text input with checkbox"
                @input="uncheckZap($event, 'whatsapp4')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group id="input-group-8" label="Estados de atuação:">
            <b-button-group size="sm" c>
              <b-button
                :pressed.sync="form.AC"
                v-model="form.AC"
                variant="outline-primary"
              >
                AC
              </b-button>
              <b-button
                :pressed.sync="form.AL"
                v-model="form.AL"
                variant="outline-primary"
              >
                AL
              </b-button>
              <b-button
                :pressed.sync="form.AP"
                v-model="form.AP"
                variant="outline-primary"
              >
                AP
              </b-button>
              <b-button
                :pressed.sync="form.AM"
                v-model="form.AM"
                variant="outline-primary"
              >
                AM
              </b-button>
              <b-button
                :pressed.sync="form.BA"
                v-model="form.BA"
                variant="outline-primary"
              >
                BA
              </b-button>
              <b-button
                :pressed.sync="form.CE"
                v-model="form.CE"
                variant="outline-primary"
              >
                CE
              </b-button>
              <b-button
                :pressed.sync="form.DF"
                v-model="form.DF"
                variant="outline-primary"
              >
                DF
              </b-button>
              <b-button
                :pressed.sync="form.ES"
                v-model="form.ES"
                variant="outline-primary"
              >
                ES
              </b-button>
              <b-button
                :pressed.sync="form.GO"
                v-model="form.GO"
                variant="outline-primary"
              >
                GO
              </b-button>
              <b-button
                :pressed.sync="form.MA"
                v-model="form.MA"
                variant="outline-primary"
              >
                MA</b-button
              >
              <b-button
                :pressed.sync="form.MT"
                v-model="form.MT"
                variant="outline-primary"
              >
                MT
              </b-button>
              <b-button
                :pressed.sync="form.MS"
                v-model="form.MS"
                variant="outline-primary"
              >
                MS
              </b-button>
              <b-button
                :pressed.sync="form.MG"
                v-model="form.MG"
                variant="outline-primary"
              >
                MG
              </b-button>
              <b-button
                :pressed.sync="form.PA"
                v-model="form.PA"
                variant="outline-primary"
              >
                PA
              </b-button>
              <b-button
                :pressed.sync="form.PB"
                v-model="form.PB"
                variant="outline-primary"
              >
                PB
              </b-button>
              <b-button
                :pressed.sync="form.PR"
                v-model="form.PR"
                variant="outline-primary"
              >
                PR
              </b-button>
              <b-button
                :pressed.sync="form.PE"
                v-model="form.PE"
                variant="outline-primary"
              >
                PE
              </b-button>
              <b-button
                :pressed.sync="form.PI"
                v-model="form.PI"
                variant="outline-primary"
              >
                PI
              </b-button>
              <b-button
                :pressed.sync="form.RJ"
                v-model="form.RJ"
                variant="outline-primary"
              >
                RJ
              </b-button>
              <b-button
                :pressed.sync="form.RN"
                v-model="form.RN"
                variant="outline-primary"
              >
                RN
              </b-button>
              <b-button
                :pressed.sync="form.RS"
                v-model="form.RS"
                variant="outline-primary"
              >
                RS
              </b-button>
              <b-button
                :pressed.sync="form.RO"
                v-model="form.RO"
                variant="outline-primary"
              >
                RO
              </b-button>
              <b-button
                :pressed.sync="form.RR"
                v-model="form.RR"
                variant="outline-primary"
              >
                RR
              </b-button>
              <b-button
                :pressed.sync="form.SC"
                v-model="form.SC"
                variant="outline-primary"
              >
                SC
              </b-button>
              <b-button
                :pressed.sync="form.SP"
                v-model="form.SP"
                variant="outline-primary"
              >
                SP
              </b-button>
              <b-button
                :pressed.sync="form.SE"
                v-model="form.SE"
                variant="outline-primary"
              >
                SE
              </b-button>
              <b-button
                :pressed.sync="form.TO"
                v-model="form.TO"
                variant="outline-primary"
              >
                TO
              </b-button>
            </b-button-group>
          </b-form-group>

          <b-button type="submit" variant="primary">SALVAR</b-button>
        </b-form>
      </b-col>
      <b-col cols="3">
        <image-fallback
          :key="imageFallbackKey"
          :image="form.link_foto"
          alt="Responsive image"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
const ImageFallback = () => import("../../components/shared/ImageFallback.vue");

export default {
  components: { ImageFallback, FormInput },
  name: "productDetails",
  data() {
    return {
      imageFallbackKey: 0,
      form: { ...this.$store.state.representative.representative },
    };
  },
  computed: {
    ...mapState("representative", ["representative"]),
  },
  methods: {
    ...mapActions("representative", [
      "createRepresentative",
      "updateRepresentative",
      "getOneRepresentative",
    ]),
    ...mapMutations("representative", ["setOverlay"]),

    async onSubmit(event) {
      event.preventDefault();
      const { form } = this;
      if (form.id) {
        this.updateRepresentative(form);
      } else {
        await this.createRepresentative(form);
        if (this.representative.id) {
          this.$router.push(`/representantes/${this.representative.id}/editar`);
          this.form = { ...this.$store.state.representative.representative };
        }
      }
    },
    uncheckZap(inputValue, zap) {
      if (!inputValue) {
        this.form[zap] = 0;
      }
    },
  },
  async created() {
    this.setOverlay(true);
    const id = this.$route.params.id;

    if (id && !this.form.id) {
      await this.getOneRepresentative(id);
      this.form = { ...this.$store.state.representative.representative };
      this.imageFallbackKey++;
    }

    this.setOverlay(false);
  },
};
</script>

<style></style>
